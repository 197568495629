<template>
  <div
    ref="referenceRef"
    class="flex"
    :class="wrapperClasses"
    @blur="hide"
    @focus="show"
    @mouseenter="show"
    @mouseleave="hide"
    @click.stop.prevent
  >
    <slot name="trigger">
      <FaIcon
        icon-class="fad fa-circle-info"
        style="
          --fa-primary-color: var(--thm-text-base);
          --fa-secondary-color: var(--thm-status-warn-base);
        "
      />
    </slot>
  </div>
  <ClientOnly>
    <Teleport to="body">
      <div
        v-if="!isHidden"
        ref="floatingRef"
        class="absolute top-0 left-0 cursor-default z-[1000]"
      >
        <div>
          <slot name="content"></slot>
        </div>

        <template v-if="!hideArrow">
          <div
            ref="arrowRef"
            class="absolute h-[10px] w-[10px] z-[999] floating-arrow"
            :class="[arrowClasses, arrowRotationClass]"
          ></div>
        </template>
      </div>
    </Teleport>
  </ClientOnly>
</template>
<script setup lang="ts">
import {
  type Axis,
  arrow,
  autoPlacement,
  autoUpdate,
  computePosition,
  flip,
  offset,
  type Placement,
  shift,
  useFloating,
} from '@floating-ui/vue';
import { ref, nextTick } from 'vue';
import FaIcon from '~/components/fa-icon.vue';
import { SSR_safe_mq_breakpointIsMobile } from '@/injectionSymbols';
import { getAlignment, getSide, getSideAxis } from '@floating-ui/utils';

const props = defineProps({
  placement: {
    type: String as PropType<Placement>,
    default: 'right-start',
  },
  floatingOffset: {
    type: Number,
    required: false,
    default: 10,
  },
  arrowOffset: {
    type: Number,
    required: false,
    default: 10,
  },
  hideArrow: {
    type: Boolean,
    default: false,
  },
  showPopup: {
    type: Boolean,
    default: false,
  },
  showOnHover: {
    type: Boolean,
    default: true,
  },
  wrapperClasses: {
    type: String,
    default: '',
  },
  arrowClasses: {
    type: String,
    default: 'bg-[var(--thm-info-popup-bg)]',
  },
  keepOpen: {
    type: Boolean,
    default: false,
  },
});

const referenceRef = ref(null);
const floatingRef = ref(null);
const arrowRef = ref(null);
const isHidden = ref(true);
const isMobile = inject(SSR_safe_mq_breakpointIsMobile);
const currentPlacement = ref(props.placement);

onMounted(() => {
  useResizeObserver(window.document.body, () => {
    if (!isHidden.value) calculatePosition();
  });
  isHidden.value = !props.showPopup;
});

onBeforeUnmount(() => {
  isHidden.value = true;
  referenceRef.value?._autoUpdateCleanup?.();
});

watch(
  () => props.placement,
  (nV) => {
    currentPlacement.value = nV;
  },
);

watch(
  () => props.showOnHover,
  (nV) => {
    if (!nV) hide();
  },
);

watch(
  () => props.showPopup,
  async (nv) => {
    isHidden.value = !nv;
    // Next tick is needed to ensure the floatingRef is already rendered
    await nextTick();
    if (nv) await calculatePosition();
  },
);

function hide() {
  if (!props.keepOpen) {
    isHidden.value = true;
  }
}

async function show() {
  if (props.showOnHover) {
    isHidden.value = false;
    // Next tick is needed to ensure the floatingRef is already rendered
    await nextTick();
    await calculatePosition();
  }
}

// Different rotation classes are needed for floating elements with arrow and border
const arrowRotationClass = computed(() => {
  switch (getSide(currentPlacement.value)) {
    case 'left':
      return 'rotate-[135deg]';
    case 'right':
      return '-rotate-45';
    case 'top':
      return '-rotate-[135deg]';
    case 'bottom':
      return 'rotate-45';
    default:
      return '';
  }
});

async function calculatePosition() {
  // check if refs exist and if we are on client side
  if (
    !import.meta.client ||
    !referenceRef.value ||
    !floatingRef.value ||
    !window ||
    !floatingRef.value.ownerDocument
  )
    return;
  useFloating(referenceRef, floatingRef, {
    whileElementsMounted(referenceEl, floatingEl, update) {
      const cleanup = autoUpdate(referenceEl, floatingEl, update, {
        layoutShift: true,
        elementResize: true,
      });

      referenceRef.value._autoUpdateCleanup = cleanup;
      return cleanup;
    },
  });

  const { x, y, middlewareData, placement } = await computePosition(
    referenceRef.value,
    floatingRef.value,
    {
      placement: currentPlacement.value,
      middleware: [
        isMobile.value ? autoPlacement() : flip(),
        offset(props.hideArrow ? 5 : 10),
        shift({
          padding: 20,
        }),
        arrow({ element: arrowRef.value }),
      ],
    },
  );

  currentPlacement.value = placement;
  const axis = getSideAxis(placement);
  Object.assign(floatingRef.value.style, {
    left: getPosition(
      x,
      axis,
      floatingRef.value,
      axis === 'x' ? 0 : props.floatingOffset,
    ),
    top: getPosition(
      y,
      axis,
      floatingRef.value,
      axis === 'y' ? 0 : props.floatingOffset,
    ),
  });

  if (props.hideArrow) return;

  const { x: arrowX, y: arrowY } = middlewareData.arrow;
  const opposedSide = {
    left: 'right',
    right: 'left',
    bottom: 'top',
    top: 'bottom',
  }[placement.split('-')[0]];

  const side = {
    x: 'top',
    y: 'left',
  }[axis];
  let arrowPosition = axis === 'x' ? arrowY : arrowX;
  const floatingSize = getAffectedElementSize(floatingRef.value, axis);
  const alignment = getAlignment(placement);
  if (alignment === 'start') {
    arrowPosition = 0;
  } else if (alignment === 'end') {
    arrowPosition = floatingSize - props.arrowOffset;
  }

  Object.assign(arrowRef.value.style, {
    top: '',
    bottom: '',
    left: '',
    right: '',
    [side]:
      arrowPosition != null
        ? getPosition(
            arrowPosition,
            axis,
            floatingRef.value,
            -(props.arrowOffset + props.floatingOffset),
          )
        : '',
    [opposedSide]: '-4px',
  });
}

function getPosition(
  position: number | undefined,
  axis: Axis,
  element: HTMLElement,
  offset = 0,
): string {
  if (position === undefined) {
    return '';
  }

  const elementSize = getAffectedElementSize(element, axis);
  const alignment = getAlignment(currentPlacement.value);
  let measuredOffset = offset;
  if (!props.hideArrow) {
    measuredOffset += props.arrowOffset;
  }

  if (elementSize < 3 * measuredOffset || alignment === undefined) {
    return `${position}px`;
  } else if (alignment === 'start') {
    return `${position - offset}px`;
  } else if (alignment === 'end') {
    return `${position + offset}px`;
  }

  return `${position}px`;
}

// If the floatingRef is positioned on the x axis, the height is what we need
// If the floatingRef is positioned on the y axis, the width is what we need
function getAffectedElementSize(el: HTMLElement, axis: Axis) {
  if (axis === 'x') {
    return el.clientHeight;
  }
  return el.clientWidth;
}
</script>
<style scoped>
/* We need this before pseudo for floating elements with arrow and border */
.floating-arrow::before {
  content: '';
  border: solid transparent;
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: inherit;
}
</style>
